import React from 'react';
import shallow from 'zustand/shallow';

import { useConfigStore } from '#src/stores/ConfigStore';
import type { Content } from '#types/Config';
import ShelfList from '#src/containers/ShelfList/ShelfList';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import styles from './Home.module.scss';
import Button from '#src/components/Button/Button';


const Home = () => {
  const { instance: MSALInstance, inProgress } = useMsal();
  const loginButtonClickHandler = () => {
    MSALInstance.loginRedirect();
    // navigate(addQueryParam(location, 'u', 'login'));
  };
  const { config } = useConfigStore(({ config, accessModel }) => ({ config, accessModel }), shallow);
  const content: Content[] = config?.content;

  return <>
    <AuthenticatedTemplate>
      <ShelfList rows={content} />
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <div className={styles.home}>
        <h1>CHQ Assembly is Moving to YouTube</h1>
        <img src="/images/android-chrome-512x512.png" alt="Assembly Logo" />
        <p>New to CHQ Assembly? Click below to join now on YouTube!</p>
        <a target="_blank" rel="noreferrer" href="https://www.youtube.com/chautauquainstitution/join">
          <Button label="Join Now" variant="contained" color="primary" size="large" />
        </a>
        <p>Already a CHQ Assembly member on YouTube? Click below to sign in!</p>
        <a target="_blank" rel="noreferrer" href="https://www.youtube.com/chautauquainstitution">
          <Button label="Sign In" variant="contained" color="primary" size="large" />
        </a>
        <p>Legacy assembly.chq.org members can login below!</p>
        <Button onClick={loginButtonClickHandler} color="primary" label="Login" />
        <p>Not sure if your legacy CHQ Assembly membership has expired? Contact <a href="mailto:assemblysupport@chq.org">assemblysupport@chq.org</a> for assistance. </p>
      </div>;
    </UnauthenticatedTemplate></>;
};

export default Home;
