import React from 'react';
import ReactDOM from 'react-dom';
import 'wicg-inert';
import { registerSW } from 'virtual:pwa-register';
import { MsalProvider } from "@azure/msal-react";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { Config } from "@cleeng/mediastore-sdk";

import App from './App';
import { cleengRedirectUrls, cleengTheme } from './helpers/cleeng';

const URLS = {
  // PayPal redirection URLs, required for PayPal payment
  successUrl: `${window.location.protocol}//${window.location.host}/u/my-account`,
  cancelUrl: `${window.location.protocol}//${window.location.host}/u/my-account`,
  errorUrl: `${window.location.protocol}//${window.location.host}/u/my-account`
}
Config.setTheme(cleengTheme);
Config.setCheckoutPayPalUrls(cleengRedirectUrls);
Config.setMyAccountPayPalUrls(cleengRedirectUrls)

Config.setMyAccountUrl(cleengRedirectUrls.successUrl)

const authority = import.meta.env.MODE === 'prod' || import.meta.env.APP_FORCE_PROD_SSO === 'true' ? "https://my.chq.org/ott/" : "https://my.chq.org/ott/sandbox/";

// MSAL configuration
const configuration: Configuration = {
  auth: {
    clientId: '3676c377-918f-451f-9774-43d0d029e331',
    authority: authority,
    knownAuthorities: ["ssochq.b2clogin.com", "my.chq.org"], // array of domains that are known to be trusted
    redirectUri: window.location.origin,
  }
};

const MSAL_PCA = new PublicClientApplication(configuration);


ReactDOM.render(
  <MsalProvider instance={MSAL_PCA}>
    <App />
  </MsalProvider>,
  document.getElementById('root')
);

registerSW();
