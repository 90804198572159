export const cleengRedirectUrls = {
    // PayPal redirection URLs, required for PayPal payment
    successUrl: `${window.location.protocol}//${window.location.host}/u/payments`,
    cancelUrl: `${window.location.protocol}//${window.location.host}/u/payments`,
    errorUrl: `${window.location.protocol}//${window.location.host}/u/payments`
}

export const cleengTheme = {
    fontColor: "#ffffff",
    backgroundColor: "rgba(255, 255, 255, 0.08)",
    cardColor: "rgba(255, 255, 255, 0.08)",
    successColor: "#435dc5",
    primaryColor: "#435dc5",
    loaderColor: "#cccccc",
    errorColor: "red",
    logoUrl: "https://www.chq.org/wp-content/uploads/2023/04/chqassemblylogo.png"
}