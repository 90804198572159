import { AuthenticatedTemplate } from "@azure/msal-react";

import Button from "#components/Button/Button";
import MenuButton from "#components/MenuButton/MenuButton";


type CHQNavProps = {
    useMenuButton?: boolean;
};

const CHQNav: React.FC<CHQNavProps> = ({useMenuButton=false}) => {
    let Item = Button;
    if (useMenuButton) {
        Item = MenuButton
    }
    return (
        <>
            <AuthenticatedTemplate>
                <Item label="My Favorites" to="u/favorites" variant="text" />
            </AuthenticatedTemplate>
            <Item label="Donate" onClick={() => { window.open("https://giving.chq.org", "_blank") }} variant="text" target="_blank" />
            <Item label="chq.org" onClick={() => { window.open("https://chq.org", "_blank") }} variant="text" target="_blank" />
        </>
    );
};

export default CHQNav;