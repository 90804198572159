import React, { useEffect } from 'react';
import { mixed, object, SchemaOf } from 'yup';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import shallow from 'zustand/shallow';

import useOffers from '#src/hooks/useOffers';
import { addQueryParam, removeQueryParam } from '#src/utils/location';
import { useCheckoutStore } from '#src/stores/CheckoutStore';
import LoadingOverlay from '#components/LoadingOverlay/LoadingOverlay';
import ChooseOfferForm from '#components/ChooseOfferForm/ChooseOfferForm';
import useForm, { UseFormOnSubmitHandler } from '#src/hooks/useForm';
import type { ChooseOfferFormData } from '#types/account';
import Button from '#src/components/Button/Button';

const ChooseOffer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation('account');
  const { setOffer } = useCheckoutStore(({ setOffer }) => ({ setOffer }), shallow);
  const { isLoading, offerType, setOfferType, offers, offersDict, defaultOfferId, hasMultipleOfferTypes, hasPremierOffer } = useOffers();

  const validationSchema: SchemaOf<ChooseOfferFormData> = object().shape({
    offerId: mixed<string>().required(t('choose_offer.field_required')),
  });

  const initialValues: ChooseOfferFormData = {
    offerId: defaultOfferId,
  };

  // const chooseOfferSubmitHandler: UseFormOnSubmitHandler<ChooseOfferFormData> = async ({ offerId }, { setSubmitting, setErrors }) => {
  //   const offer = offerId && offersDict[offerId];

  //   if (!offer) return setErrors({ form: t('choose_offer.offer_not_found') });

  //   setOffer(offer);
  //   setSubmitting(false);
  //   navigate(addQueryParam(location, 'u', 'checkout'));
  // };

  // const { handleSubmit, handleChange, setValue, values, errors, submitting } = useForm(initialValues, chooseOfferSubmitHandler, validationSchema);

  // useEffect(() => {
  //   // close auth modal when there are no offers defined in the config
  //   if (!isLoading && !offers.length) navigate(removeQueryParam(location, 'u'), { replace: true });
  // }, [isLoading, offers, location, navigate]);

  // useEffect(() => {
  //   setValue('offerId', defaultOfferId);
  // }, [setValue, defaultOfferId]);

  // loading state
  // if (!offers.length || isLoading) {
  //   return (
  //     <div style={{ height: 300 }}>
  //       <LoadingOverlay inline />
  //     </div>
  //   );
  // }

  return (
    // <ChooseOfferForm
    //   onSubmit={handleSubmit}
    //   onChange={handleChange}
    //   values={values}
    //   errors={errors}
    //   submitting={submitting}
    //   offers={offers}
    //   offerType={offerType}
    //   setOfferType={hasMultipleOfferTypes && !hasPremierOffer ? setOfferType : undefined}
    // />
    <div>
      <p>
        We have migrated our service to YouTube, where you can join as a CHQ Assembly member. To explore our new membership options and upgrade your experience, visit <a target="_blank" href="https://www.youtube.com/chautauquainstitution/join">www.youtube.com/chautauquainstitution/join</a> to find our YouTube channel and join today.      </p>
      <p>
        For questions, please reach out to our support team at <a href="mailto:assemblysupport@chq.org">assemblysupport@chq.org</a>. We appreciate your interest and can't wait to have you as part of our community!
      </p>
      <a target="_blank" rel="noreferrer" href="https://www.youtube.com/chautauquainstitution/join"><Button label="Go To YouTube" variant="contained" color="primary" size="large" fullWidth /></a>
    </div>
  );
};

export default ChooseOffer;
