import React, { useEffect, useState } from 'react';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import { Config } from "@cleeng/mediastore-sdk";

import QueryProvider from '#src/containers/QueryProvider/QueryProvider';
import '#src/screenMapping';
import '#src/styles/main.scss';
import initI18n from '#src/i18n/config';
import Root from '#components/Root/Root';
import { ErrorPageWithoutTranslation } from '#components/ErrorPage/ErrorPage';
import LoadingOverlay from '#components/LoadingOverlay/LoadingOverlay';
import { forceLogin } from '#src/stores/AccountController';
import { useConfigStore } from '#src/stores/ConfigStore';
import { cleengTheme, cleengRedirectUrls } from '#src/helpers/cleeng';

interface State {
  isLoading: boolean;
  error?: Error;
}



export default function App() {
  const [i18nState, seti18nState] = useState<State>({ isLoading: true });
  const { instance: MSALInstance, inProgress: MSALInProgress } = useMsal();
  const siteid = useConfigStore((s) => s.config.id)
  const config = useConfigStore();

  async function setupMSAL() {
    await MSALInstance.initialize();
    MSALInstance.handleRedirectPromise().then((tokenResponse) => {
      if (tokenResponse === null) return;
      if (!("extension_cleengJWT" in tokenResponse.idTokenClaims && "extension_cleengRefresh" in tokenResponse.idTokenClaims)) return;
      const cleengJWT = tokenResponse.idTokenClaims.extension_cleengJWT as string;
      const cleengRefresh = tokenResponse.idTokenClaims.extension_cleengRefresh as string;
      let waitingForConfig = false
      function checkIfConfigLoaded() {
        if (waitingForConfig) return
        waitingForConfig = true
        setTimeout(() => {
          if (siteid) {
            console.debug("Config loaded")
            forceLogin(cleengJWT, cleengRefresh);

            Config.setEnvironment(config.config.integrations.cleeng.useSandbox ? 'sandbox' : 'production');
            Config.setJWT(cleengJWT); // required
            Config.setRefreshToken(cleengRefresh); // optional
            Config.setTheme(cleengTheme);
            Config.setCheckoutPayPalUrls(cleengRedirectUrls);
            Config.setMyAccountPayPalUrls(cleengRedirectUrls)

            Config.setMyAccountUrl(cleengRedirectUrls.successUrl)
          } else {
            console.debug("Still waiting")
            checkIfConfigLoaded()
          }
        }, 500)
      }
      checkIfConfigLoaded()
    }).catch((error) => {
      console.error(error);
    });

  }

  setupMSAL()

  useEffect(() => {
    initI18n()
      .then(() => seti18nState({ isLoading: false }))
      .catch((e) => seti18nState({ isLoading: false, error: e as Error }));
  }, []);

  if (i18nState.isLoading || MSALInProgress !== "none") {
    return <LoadingOverlay />;
  }

  if (i18nState.error) {
    // Don't be tempted to translate these strings. If i18n fails to load, translations won't work anyhow
    return (
      <ErrorPageWithoutTranslation
        title={'Unable to load translations'}
        message={'Check your language settings and try again later. If the problem persists contact technical support.'}
        error={i18nState.error}
      />
    );
  }

  const Router = import.meta.env.APP_PUBLIC_GITHUB_PAGES ? HashRouter : BrowserRouter;

  return (
    <QueryProvider>
      <Router>
        <Root />
      </Router>
    </QueryProvider>
  );
}
