import linkifyHtml from 'linkify-html';

type Props = {
    description: string;
};

const PrettyVideoDescription: React.FC<{ description: string }> = ({ description }: Props) => {
    let prettyText = description;

    // Replace all newlines with <br />
    prettyText = prettyText.replace(/\n/g, '<br />');

    // Replace all URLs with <a href="...">...</a>
    prettyText = linkifyHtml(
        prettyText,
        { defaultProtocol: "https", target: { url: "_blank", email: "" } }
    );

    return (
        <div>
            <div dangerouslySetInnerHTML={{ __html: prettyText }} />
        </div>
    );
};

export default PrettyVideoDescription;