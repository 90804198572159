import React from 'react';

const Help = () => {
    window.location.href = "https://www.chq.org/join-chq-assembly/";

    return (
        <div style={{
            "marginTop": "5em",
            "textAlign": "center",
        }}>
            <h1>Redirecting to the Help Center</h1>
            <p>If you are not redirected automatically, please click <a href="https://www.chq.org/join-chq-assembly/">here</a>.</p>
        </div>
  );
};

export default Help;
