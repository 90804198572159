import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Config, PaymentInfo, store } from "@cleeng/mediastore-sdk";
import { Provider } from "react-redux";

import styles from './Payment.module.scss';

import TextField from '#components/TextField/TextField';
import LoadingOverlay from '#components/LoadingOverlay/LoadingOverlay';
import Button from '#components/Button/Button';
import type { Customer } from '#types/account';
import { formatDate, formatPrice } from '#src/utils/formatting';
import { addQueryParam } from '#src/utils/location';
import type { PaymentDetail, Subscription, Transaction } from '#types/subscription';
import type { AccessModel } from '#types/Config';
import { useAccountStore } from '#src/stores/AccountStore';
import { useConfigStore } from '#src/stores/ConfigStore';
import { useCheckoutStore } from '#src/stores/CheckoutStore';

const VISIBLE_TRANSACTIONS = 4;

type Props = {
  accessModel: AccessModel;
  activeSubscription: Subscription | null;
  activePaymentDetail: PaymentDetail | null;
  transactions: Transaction[] | null;
  customer: Customer;
  isLoading: boolean;
  panelClassName?: string;
  panelHeaderClassName?: string;
  onShowAllTransactionsClick?: () => void;
  showAllTransactions: boolean;
  canRenewSubscription?: boolean;
};

const Payment = ({
  accessModel,
  activePaymentDetail,
  activeSubscription,
  transactions,
  customer,
  isLoading,
  panelClassName,
  panelHeaderClassName,
  onShowAllTransactionsClick,
  showAllTransactions,
  canRenewSubscription = false,
}: Props): JSX.Element => {
  const { t } = useTranslation(['user', 'account']);
  const hiddenTransactionsCount = transactions ? transactions?.length - VISIBLE_TRANSACTIONS : 0;
  const hasMoreTransactions = hiddenTransactionsCount > 0;
  const navigate = useNavigate();
  const location = useLocation();
  const setRequestedMediaOffers = useCheckoutStore((s) => s.setRequestedMediaOffers);

  function onCompleteSubscriptionClick() {
    navigate(addQueryParam(location, 'u', 'choose-offer'));
  }

  function onCancelSubscriptionClick() {
    navigate(addQueryParam(location, 'u', 'unsubscribe'));
  }

  function onRenewSubscriptionClick() {
    navigate(addQueryParam(location, 'u', 'renew-subscription'));
  }

  function getTitle(period: Subscription['period']) {
    switch (period) {
      case 'month':
        return t('user:payment.monthly_subscription');
      case 'year':
        return t('user:payment.annual_subscription');
      case 'day':
        return t('user:payment.daily_subscription');
      case 'week':
        return t('user:payment.weekly_subscription');
      case 'granted':
        return t('user:payment.granted_subscription');
      default:
        return t('user:payment.other');
    }
  }

  const account = useAccountStore();
  const config = useConfigStore();

  Config.setEnvironment(config.config.integrations.cleeng.useSandbox ? 'sandbox' : 'production');
  Config.setJWT(account.auth?.jwt); // required
  Config.setRefreshToken(account.auth?.refreshToken); // optional
  Config.setTheme({
    fontColor: "#ffffff",
    backgroundColor: "rgba(255, 255, 255, 0.08)",
    cardColor: "rgba(255, 255, 255, 0.08)",
    successColor: "#435dc5",
    primaryColor: "#435dc5",
    loaderColor: "#cccccc",
    errorColor: "red",
    logoUrl: "https://www.chq.org/wp-content/uploads/2023/04/chqassemblylogo.png"
  });

  useEffect(() => {
    // Inject weekly pass to the store
    setRequestedMediaOffers([{"offerId": "P698314117_US", "premier": false}])
    
    return () => setRequestedMediaOffers(null);
  }, [setRequestedMediaOffers]);
  
  return (
    <>
      {accessModel === 'SVOD' && (
        <div className={panelClassName}>
          <div className={panelHeaderClassName}>
            <h3>{t('user:payment.subscription_details')}</h3>
          </div>
          {activeSubscription ? (
            <React.Fragment>
              <div className={styles.infoBox} key={activeSubscription.subscriptionId}>
                <p>
                  <strong>{getTitle(activeSubscription.period)}</strong> <br />
                  {activeSubscription.status === 'active' && activeSubscription.period !== 'granted'
                    ? t('user:payment.next_billing_date_on', { date: formatDate(activeSubscription.expiresAt) })
                    : t('user:payment.subscription_expires_on', { date: formatDate(activeSubscription.expiresAt) })}
                </p>
                <p className={styles.price}>
                  <strong>{formatPrice(activeSubscription.nextPaymentPrice, activeSubscription.nextPaymentCurrency, customer.country)}</strong>
                  <small>/{t(`account:periods.${activeSubscription.period}`)}</small>
                </p>
              </div>
              {activeSubscription.status === 'active' && activeSubscription.period !== 'granted' ? (
                <Button label={t('user:payment.cancel_subscription')} onClick={onCancelSubscriptionClick} />
              ) : canRenewSubscription ? (
                <Button label={t('user:payment.renew_subscription')} onClick={onRenewSubscriptionClick} />
              ) : null}
            </React.Fragment>
          ) : isLoading ? null : (
            <React.Fragment>
              <p>{t('user:payment.no_subscription')}</p>
              <Button variant="contained" color="primary" label={t('user:payment.complete_subscription')} onClick={onCompleteSubscriptionClick} />
            </React.Fragment>
          )}
        </div>
      )}

      <Provider store={store}>
        <PaymentInfo
          // adyenConfiguration={adyenConfiguration}
          displayGracePeriodError
        />
      </Provider>
      {isLoading && <LoadingOverlay inline />}
    </>
  );
};

export default Payment;
